import DataHandler from 'o365.modules.InsightsDataHandler.ts';
import { getOrCreateDataObject } from 'o365.vue.ts';
import type DataObject from 'o365.modules.DataObject.ts';

const dataObjectsMap:Map<string,DataObject> = new Map();
export function getDataObject(pOptions:any):DataObject{
    const vKey = pOptions.identity ?? pOptions.logTable;
    if(!dataObjectsMap.has(vKey)){
        const vDataHandler = new DataHandler(pOptions.logTable);
        if(pOptions.duration) vDataHandler.duration = "24:00:00";
        dataObjectsMap.set(vKey,getOrCreateDataObject({
            dataHandler:vDataHandler,
            id:vKey,
            fields:pOptions.fields,
            whereClause:pOptions.whereClause,
            maxRecords:pOptions.maxRecords??500
        }));

    }

    return dataObjectsMap.get(vKey);

}